/**
 * 
 * @param {string} clickObj eachの対象obj
 * 
 * ブラウザ幅が751px以下の時は1を、それ以外は0を返します
 * 
 */

let windowWidth = $(window).width();
let windowSm = 750;
let uaVal;

if (windowWidth <= windowSm) {
  uaVal = 1;
} else {
  uaVal = 0;
}

const UA_VAL = uaVal;
export {
  UA_VAL as default
};
