import Swiper from "swiper";
import uaNum from "./uaJudgment.js";
/**
 * スライド
 * @constructor
 */

class Slide {
  constructor() {
    this.slideBtn = $('.js-slideBtn img');
  }
  /**
   * 
   */
  init() {
    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;

    var sliderSelector = '.m-seminarSlide__inner'; // スライダーのコンテナーとなる要素のセレクタ
    var slidesPerView = 1; // スライド表示数
    var $slide = $(sliderSelector + ' .swiper-slide');
    var slidable = $slide.length > slidesPerView;

    // セミナースライド
    var mySwiper = new Swiper ('.m-seminarSlide__inner', {
      loop: slidable,
      spaceBetween: 50,
      slidesPerView: slidesPerView,
      centeredSlides: true,
      speed: 1000,
      autoplay: {
      delay: 8000,
      disableOnInteraction: false,//ユーザーインタラクション（スワイプ）後に自動再生
        // when window width is >= 751px
        breakpoints: {//pcだった場合
          751: {
            spaceBetween: 0,
          }
        }
      },
      // オプションの設定
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      watchOverflow: true,
    })

    if (uaNum == 1) { //SPだった場合

    } else if (uaNum == 0) {//pcだった場合
      // スライドボタン hover画像切り替え
      this.slideBtn.on('mouseenter',  (e) => {
        this.slideBtn.attr("src", $(e.currentTarget).attr("src").replace(/(\.)(png|jpg|gif)/, "_hover$1$2"));
      });
      this.slideBtn.on('mouseleave', (e) => {
        this.slideBtn.attr("src", $(e.currentTarget).attr("src").replace("_hover", ""));
      });
    }
  }
};

export default Slide;
