import uaNum from "./uaJudgment.js";

/**
 * hover(アニメーション用)
 * @constructor
 */
class Hover {
  constructor() {
    this.hover = $('.line,.hover,.hover02');
  }
  /**
   * 
   */
  init() {
    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;

    this.hover.on('mouseenter',  () => {
      this.hover.removeClass('is-hover');
    });

    this.hover.on('mouseleave', (e) => {
      // this.hover.removeClass('is-hover');
      $(e.currentTarget).addClass('is-hover');
    });


    // SPタッチイベント
    // if (uaNum == 1) { //SPだった場合
    //   $('.js-spTouch').on('touchstart', function(){
    //     $(this).addClass('is-touch');
    //   })
    //   $('.js-spTouch').on('touchend', function(){
    //     $(this).removeClass('is-touch');
    //   })

    // } else if (uaNum == 0) {//pcだった場合

    // }
  }
}

export default Hover;
