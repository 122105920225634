/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';
// import Swiper from "swiper";

/**
 * import modules
 */
import Sample from './modules/sample';
import Common from './modules/common';
import HeaderBtn from './modules/headerBtn';
import HeaderScl from './modules/headerScl';
import Hover from './modules/hover';
import Slide from './modules/slide';
import Video from './modules/video';
import SclAnim from './modules/sclAnim';

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();

      // transition無効 解除(Chrome対応)
      $("body").removeClass("is-preload");
    });

        // iOS ブラウザバック対策
    // window.onpageshow = function(event) {
    //   if (event.persisted) { // 有効時の処理
    //       // 「戻る」ボタンを押したらリロード
    //        window.location.reload();
    //    }
    // };
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    // topページのみ
    if($('#p-top').length>0){
      // kv用
      const video = new Video();
      video.init();
    }

    else{
      // topページ以外の処理
      $('.l-header').removeClass('is-top');
    }

    const sample = new Sample();
    sample.init();

    const common = new Common();
    common.init();

    const headerBtn = new HeaderBtn();
    headerBtn.init();

    const headerScl = new HeaderScl();
    headerScl.init();

    const sclAnim = new SclAnim();
    sclAnim.init();

    const hover = new Hover();
    hover.init();

    const slide = new Slide();
    slide.init();

  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {

    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    this.init_smoothScroll();
  }



  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
