import uaNum from "./uaJudgment.js";

/**
 * header スクロール
 * @constructor
 */
class HeaderScl {
  constructor() {
  }
  /**
   * スクロール時 Header 
   */
  init() {
    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;

    // 1.header スクロール 位置を移動 （全ページ）
    $(window).scroll(function () {
      if ($(this).scrollTop() > 600) {// ページトップからスクロール位置600px以上の時
        $('.js-frame').addClass('is-active');
      } else {
        $('.js-frame').removeClass('is-active');
      }
    });

  }
}

export default HeaderScl;
