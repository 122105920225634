import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);


/** topへ戻るボタン
 *  トップページ kv動画 パララックスでの幅調整
 * @constructor
 */
class SclAnim {
    constructor() {
    }
  init() {


    // topへ戻るボタン footer上で停止
    var controller = new ScrollMagic.Controller({
      addIndicators: true,
    });

    new ScrollMagic.Scene({
      triggerElement: ".l-footer",
      duration: 0,
      triggerHook: "onEnter",
      reverse: true,
      offset: -14
    })
    .setClassToggle(".l-footerToTop", "is-stop")
    .addTo(controller);

    // topへ戻るボタン 表示
    var toTop = new ScrollMagic.Controller({
      addIndicators: true,
    });
    new ScrollMagic.Scene({
      triggerElement: ".l-main",
      duration: 0,
      triggerHook: "onLeave",
      reverse: true,
      offset: 500
    })
    .setClassToggle(".l-footerToTop", "is-show")
    .addTo(toTop);

    if($('#p-top').length>0){// トップページ(日本語.英語両方)
      // ページ一番下にスクロールした際に動画が見えないように対応(SP用対応)
      var videoBottom = new ScrollMagic.Controller({
        addIndicators: true,
      });
      new ScrollMagic.Scene({
        triggerElement: ".p-topLink",
        duration: 0,
        triggerHook: "onLeave",
        reverse: true,
        offset: 0
      })
      .setClassToggle(".p-topKv__video", "is-hide")
      .addTo(videoBottom);
    }else{}


    if($('#p-jaTop').length>0){// トップページ(日本語のみ)
      //kv動画 スクロール時にクラス追加(経営理念の背景)
      var video = new ScrollMagic.Controller({
        addIndicators: true,
      });
      new ScrollMagic.Scene({
        triggerElement: ".p-topPhilosophy",
        duration: 0,
        triggerHook: "onEnter",
        reverse: true,
        offset: 0
      })
      .setClassToggle(".p-topKv__video--ja", "is-change")
      .addTo(video);
    }else{}

  }
}

  export default SclAnim;
