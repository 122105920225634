import uaNum from "./uaJudgment.js";

/**
 * トップページ kvアニメーション 表示順番
 * @constructor
 */
class Video {
    constructor() {
    }
    /**
     * トップ
     */
    init() {
      var pc = window.matchMedia("(min-width: 751px)").matches;
      var sp = window.matchMedia("(max-width: 750px)").matches;

        // top動画
        if($('#topVideo').length){// トップページ読み込み時
          const $video = document.getElementById('topVideo');
          // var videoSp = document.getElementById('topVideoSp');

          if (uaNum == 1) { //SPだった場合
            // // sp用kv動画読みこみ
            $('#topVideo').attr("src", $('#topVideo').attr("src").replace(/(\.)(mp4)/, "_sp$1$2"));
            } else if (uaNum == 0) {//pcだった場合
          }

          const keyName = 'visited';
          const keyValue = true;
          if(!sessionStorage.getItem(keyName)) {//初回アクセス時の処理
            //sessionStorageにキーと値を追加
            sessionStorage.setItem(keyName, keyValue);

            // 読み込み時 スクロールを止める
            $('body').addClass("is-noScroll");
            $('.topLoading').addClass('is-show');

            const svg = document.querySelector(".js-svg");

            svg.addEventListener('animationend', () => {
              // ロゴアニメーション終了後
              $('.js-svg').delay(0).queue(function(){
                // svg画像
                $('.js-svg').css('opacity','0');
                // // ローディング画像削除
                $('.topLoading').removeClass('is-show');
              });

              // ロゴアニメーション終了 1秒後
              $('.p-topKv__video').delay(700).queue(function(){
                // kv背景動画表示
                $('.p-topKv__video').css('opacity','1');
                $('.p-topKv__video').addClass('is-active');
                // kvタイトル表示
                $('.p-topKv__title').css('opacity','1');

                //読み込み時 チラつき防止
                $('.p-top').css('opacity','1');
                  });

              // ロゴアニメーション終了 1.5秒後
              $('.l-header').delay(1500).queue(function(){
                // ヘッダー上から表示
                $(this).removeClass('is-top');
                // トップページ読み込み時 スクロール有効化
                $('body').removeClass("is-noScroll");
              })
            },);
          }else{//他ページから戻ってきたとき
            // スクロール有効化
            $('body').removeClass("is-noScroll");
            // ヘッダー上から表示
            $('.topLoading').removeClass('is-show');
            $('.p-top').css('opacity','1');
            $('.js-svg').css('display','none');
            $('.l-header').removeClass('is-top');
            // kvタイトル表示
            $('.p-topKv__title').css('opacity', '1');
            // kv背景動画表示
            $('.p-topKv__video').css('opacity', '1');
            $('.p-topKv__video').addClass('is-active');
          }
      }
    }
};

export default Video;
