/**
 * SP Menu
 * @constructor
 */
class HeaderBtn {
  constructor() {
    this.spMenuBtn = $('.js-spMenuBtn');
    this.spMenu = $('.js-spMenu');
    this.splink = $('.js-splink,.l-headerSpIcons__item');
  }
  /**
   * 
   */
  init() {
    this.spMenuBtn.on('click', () => {
      this.showSpMenu();
    });

    // ページ内リンククリック
    this.splink.on('click',() => {
      this.hideSpMenu()
    });

  }

  /**
   * SP HG　メニューを開く
   */
  showSpMenu() {
    this.spMenuBtn.toggleClass('is-active');
    this.spMenu.toggleClass('is-active');
    this.spMenu.slideDown();
    this.bodyScrollFixed();
  }

  /**
   * SP メニューを閉じる
   */
  hideSpMenu() {
    this.spMenuBtn.removeClass('is-active');
    this.spMenu.removeClass('is-active');
    this.spMenu.slideUp();
    $('body').removeClass("is-noScroll");
    $('.js-frame').removeClass('is-open');
  }

  /**
   * スクロールを止める
   */
  bodyScrollFixed(){
    if(this.spMenuBtn.hasClass("is-active")){
      this.pos_y = $(window).scrollTop();
      $('body').addClass("is-noScroll");
      $('.js-frame').addClass('is-open');

    }else{
      $(window).scrollTop(this.pos_y);
      $('body').removeClass("is-noScroll");
      $('.js-frame').removeClass('is-open');

    }
  }

}

export default HeaderBtn;
